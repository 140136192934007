import { Col, Empty, Row } from "antd";
import NotificationIcon from "../../assets/notification-icon-red.svg";
import "./NotificationList.css";
import { useEffect, useState } from "react";
import {
  formatDate,
  getTimeAMPMFromDate,
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { getNotificationApi, readNotification } from "../../apis/notification";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { CREDENTIALS } from "../../utils/Constants";

const NotificationList = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getNotificationApi({ limit: 500, page: 1 });
      if (response?.data?.statusCode === 200) {
        setNotificationList(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const markReadNotifcation = async (id) => {
    try {
      const response = await readNotification({ id: id });
      if (response.status == 200) {
        console.log("read");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(CREDENTIALS.AUTH_TOKEN)?.length > 1) {
      fetchData();
    }
  }, []);

  const navigateToRoute = (notification) => {
    switch (notification?.data?.type) {
      case "auction":
        navigate(`/auction-detail/${notification?.data?.auctionId}`);
        break;
      case "auctionAdd":
        navigate(`/auction-detail/${notification?.data?.auctionId}`);
        break;
      case "auctionBulk":
        navigate(`/auctions`);
        break;
      case "auctionWon":
        navigate(`/auction-detail/${notification?.data?.auctionId}`);
        break;
      case "auctionLost":
        navigate(`/auction-detail/${notification?.data?.auctionId}`);
        break;
      default:
        break;
    }
    markReadNotifcation(notification?.id);
  };

  return (
    <div className="notifications-main">
      <Loader loading={loading} />

      {notificationList.length > 0 && isLoggedIn ? (
        notificationList.map((notif) => (
          <Row
            gutter={[0, 10]}
            className="notifications-row"
            onClick={() => navigateToRoute(notif)}
            style={{
              cursor: "pointer",
              backgroundColor: !notif?.isRead ? "#EAEAEE" : "transparent",
              padding: "10px 5px",
              borderRadius: 10,
            }}
          >
            <Col md={24}>
              <Row
                className="main-row"
                align={"middle"}
                justify={"space-between"}
              >
                <Row align={"middle"}>
                  <img
                    className="notify-ocon"
                    src={NotificationIcon}
                    alt={"notifcation"}
                  />

                  <div>
                    <p className="notif-msg medium__font">
                      {`${renderItemDataOrEmptyNull(notif?.data?.body)}`}{" "}
                      {notif?.type?.length && notif?.data?.title && (
                        <span className="type-label">
                          {" "}
                          {renderItemDataOrEmptyNull(notif?.data?.title)}
                        </span>
                      )}
                    </p>

                    <p className="notif-date t-slate medium__font">
                      {formatDate(notif?.createdAt)}
                    </p>
                  </div>
                </Row>
                <Row justify={"space-between"}>
                  <p className="notif-date t-slate medium__font">
                    {getTimeAMPMFromDate(notif?.createdAt)}
                  </p>
                </Row>
              </Row>
            </Col>
          </Row>
        ))
      ) : (
        <Empty
          description={
            isLoggedIn
              ? t("noNotifications")
              : t("youNeedToLoginToViewNotifications")
          }
        />
      )}
    </div>
  );
};

export default NotificationList;
