import { useMediaQuery } from "@uidotdev/usehooks";
import { Card, Col, Empty, Radio, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWatchlistAPI } from "../../apis/watchlist/WatchlistAPI";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import HomeAuctionList from "../../components/HomeAuctionList/HomeAuctionList";
import ProtectedHeader from "../../components/ProtectedHeader/ProtectedHeader";
import filterIcon from "../../assets/filter-icon.svg";
import backIcon from "../../assets/back-arrow-icon.svg";
import { _setWatchlistAction } from "../../redux/actions/watchlist/WatchListActions";
import { useTranslation } from "react-i18next";
import {
  renderItemDataOrEmptyNull,
  requestErrorHandel,
} from "../../utils/common-functions";
import { getAllManufacturerAPI } from "../../apis/manufacturer/manufacturer";
import { getAllGradesAPI } from "../../apis/grades/index";
import Loader from "../../components/Loader/Loader";
import socket from "../../socket";
import moment from "moment";
import { CREDENTIALS } from "../../utils/Constants";

const WatchList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const watchlist = useSelector((state) => state.watchlist.data);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const [loading, setLoading] = useState(false);

  const [openFilterSheet, setOpenFilterSheet] = useState(false);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [gradeFilter, setGradeFilter] = useState("");
  const [endingFilter, setEndingFilter] = useState("");
  const [manufacturerFilter, setManufacturerFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const getManufacturerApi = async () => {
    setLoading(true);
    try {
      const response = await getAllManufacturerAPI();
      if (response?.data?.statusCode === 200) {
        setManufacturerList(response?.data?.data);
        const allChildrenArray = response?.data?.data.reduce((acc, obj) => {
          return acc.concat(obj.models);
        }, []);
        setModelsList(allChildrenArray);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const getGradesApi = async () => {
    setLoading(true);
    try {
      const response = await getAllGradesAPI();
      if (response?.data?.statusCode === 200) {
        setGradesList(response?.data?.data);
      }
    } catch (error) {
      requestErrorHandel(error, navigate);
    } finally {
      setLoading(false);
    }
  };

  const fetchWatchlist = useCallback(async () => {
    try {
      const authToken = localStorage.getItem(CREDENTIALS.AUTH_TOKEN);
      if (authToken?.length < 1 || !authToken) {
        return;
      }
      const result = await fetchWatchlistAPI(
        gradeFilter,
        endingFilter,
        manufacturerFilter,
        modelFilter,
        typeFilter
      );
      if (result.data?.statusCode === 200 && result.data.data) {
        dispatch(_setWatchlistAction(result.data.data));
      }
    } catch (e) {
      console.log("error in fetch watchlist...", e);
    }
  }, [
    dispatch,
    gradeFilter,
    endingFilter,
    manufacturerFilter,
    modelFilter,
    typeFilter,
  ]);

  const handleSocketEvent = useCallback(async () => {
    try {
      await fetchWatchlist();
    } catch (error) {
      return;
    }
  }, []);

  useEffect(() => {
    socket.on("auction", (data) => {
      handleSocketEvent(data);
    });
    return () => {
      socket.off("auction", handleSocketEvent);
    };
  }, [handleSocketEvent]);

  useEffect(() => {
    fetchWatchlist();
  }, [fetchWatchlist]);

  useEffect(() => {
    getManufacturerApi();
    getGradesApi();
  }, []);

  const onChange = (groupName) => (e) => {
    const value = e.target.value;
    switch (groupName) {
      case "manufacturer":
        setManufacturerFilter(value);
        break;
      case "model":
        setModelFilter(value);
        break;
      case "type":
        setTypeFilter(value);
        setEndingFilter("");
        break;
      case "grade":
        setGradeFilter(value);
        break;
      case "isSoon":
        setEndingFilter(value);
        setTypeFilter("");
        break;
      default:
    }
  };

  const goBack = () => navigate(-1);

  const onOpenBottomSheet = () => setOpenFilterSheet(true);
  const resetFilters = () => {
    setEndingFilter("");
    setGradeFilter("");
    setManufacturerFilter("");
    setModelFilter("");
    getManufacturerApi();
    setTypeFilter("");
  };

  const renderFilters = () => {
    return (
      <Card>
        <Row justify={"space-between"}>
          <p className="semiBold__font">{t(`auctions.viewBy`)}</p>
          <p className="t-red semiBold__font t-cursor" onClick={resetFilters}>
            {t(`auctions.reset`)}
          </p>
        </Row>

        {/* Auction Ending */}
        <p className="t-slate-light medium__font">
          {t(`filter.auctionEnding`)}
        </p>
        <Radio.Group onChange={onChange("isSoon")} value={endingFilter}>
          <Space direction="vertical">
            <Radio value={"1"}>{t(`filter.soon`)}</Radio>
          </Space>
        </Radio.Group>

        <p className="t-slate-light medium__font">{t(`others`)}</p>

        <Radio.Group onChange={onChange("type")} value={typeFilter}>
          <Space direction="vertical">
            <Radio value={"lowPrice"}>{t(`filter.priceLowToHigh`)}</Radio>
            <Radio value={"highPrice"}>{t(`filter.priceHighToLow`)}</Radio>
            <Radio value={"lowBids"}>{t(`filter.lowBids`)}</Radio>
            <Radio value={"highBids"}>{t(`filter.highBids`)}</Radio>
            <Radio value={"latest"}>{t(`filter.latest`)}</Radio>
            <Radio value={"oldest"}>{t(`filter.oldest`)}</Radio>
            <Radio value={"locked"}>{t(`filter.lock`)}</Radio>
            <Radio value={"unLocked"}>{t(`filter.unlock`)}</Radio>
          </Space>
        </Radio.Group>

        {/* manufacturer */}
        <p className="t-slate-light medium__font">{t(`manufacturer`)}</p>
        <Radio.Group
          onChange={onChange("manufacturer")}
          value={manufacturerFilter}
        >
          {manufacturerList?.map((item) => {
            return (
              <div>
                <Radio
                  onClick={() => {
                    setModelsList(item?.models);
                  }}
                  value={item?.id}
                >
                  {renderItemDataOrEmptyNull(item?.name)}
                </Radio>
              </div>
            );
          })}
        </Radio.Group>

        {/* models */}
        {/* <p className="t-slate-light medium__font">Models</p>
        <Radio.Group onChange={onChange("model")} value={modelFilter}>
          {modelsList?.map((modelItem) => {
            return (
              <div>
                <Radio value={modelItem?.id}>{modelItem?.name}</Radio>
              </div>
            );
          })}
        </Radio.Group> */}

        {/* Grade */}
        <p className="t-slate-light medium__font">{t(`filter.grade`)}</p>
        <Radio.Group onChange={onChange("grade")} value={gradeFilter}>
          {gradesList?.map((item) => {
            return (
              <div>
                <Radio value={item?.id}>{item?.title}</Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Card>
    );
  };

  const handleAuctionClosed = (id) => {
    const _data = [...watchlist];
    const updatedAuctionList = _data.filter((auction) => {
      const currentTime = moment().tz("America/Chicago");
      const endsAtTime = moment(auction.endAt);
      const timeDifference = endsAtTime.diff(currentTime, "milliseconds");

      return timeDifference > 0; // Filter items with positive time difference
    });

    // Update the state with the filtered list of auctions
    dispatch(_setWatchlistAction(updatedAuctionList));
  };
  return (
    <div className="common-layout all-auction-main">
      <Loader loading={loading} />
      <ProtectedHeader />
      <section>
        <Row
          justify={"space-between"}
          align={"middle"}
          className={"auction-header-row"}
        >
          {isSmallDevice && (
            <button onClick={goBack}>
              <img src={backIcon} alt={"back"} />
            </button>
          )}
          <h2 className="bold__font">{t(`watchlist.watchlist`)}</h2>
          {isSmallDevice && (
            <button onClick={onOpenBottomSheet}>
              <img src={filterIcon} alt={"filter"} />
            </button>
          )}
        </Row>
        <Row className="auction-row" gutter={[20]}>
          {!isSmallDevice && (
            <Col md={6} className="filter-section">
              {renderFilters()}
            </Col>
          )}
          <Col md={16} xs={24}>
            {watchlist.length > 0 ? (
              <HomeAuctionList
                data={watchlist}
                type={"auctions-page"}
                onAuctionClosed={handleAuctionClosed}
              />
            ) : (
              <Empty
                description={<p className="medium__font">{t("noDataFound")}</p>}
              />
            )}
          </Col>
        </Row>

        <BottomSheet open={openFilterSheet}>{renderFilters()}</BottomSheet>
      </section>
    </div>
  );
};

export default WatchList;
