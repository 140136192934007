import client from "../config";

export const getNotificationApi = async ({ limit = 200, page = 1 }) => {
  return await client.get(`/notifications?limit=${limit}&page=${page}`);
};

export const readNotification = async ({ id }) => {
  return await client.put(`/notifications/${id}`);
};

export const getNotificationBadge = async () => {
  return await client.get("/notifications-status");
};
